//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg = /^[a-zA-Z0-9]{8}$/;
        let flag = reg.test(value);
        if (!flag) {
          callback(new Error("密码必须由8位数字或字母组成"));
        } else {
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== "") {
        let reg = /^[a-zA-Z0-9]{8}$/;
        let flag = reg.test(value);
        if (!flag) {
          callback(new Error("密码必须由8位数字或字母组成"));
        }
      } else {
        callback();
      }
    };
    return {
      fileList: [],
      ruleForm: {
        lastPassword: "",
        nowPassword: "",
      },
      rules: {
        // nowPassword: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validatePass,
        //   },
        // ],
        // lastPassword: [{
        // 	trigger: 'blur',
        // 	validator: validatePass2
        // }]
      },
    };
  },
  components: {
    XModal: () => import("@/components/XModal"),
  },
  methods: {
    initData() {
      this.ruleForm.lastPassword = "";
      this.ruleForm.nowPassword = "";
      this.fileList = [];
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      // this.$refs.modal.loadBtn(false)
    },
    handlePreview(file) {
      //点击已上传至服务器的文件
      console.log(file);
    },
    onSuccess(res, file, fileList) {
      this.$refs.modal.loadBtn(true);
      // console.log(this.fileList)
      if (file.response.res == 0) {
        this.$http
          .post("/Teams/MemberImport.ashx", {
            path: file.response.link,
            firstEncry: this.ruleForm.lastPassword,
            encoryStr: this.ruleForm.nowPassword,
            teamId: this.teamId,
          })
          .then((res) => {
            console.log(res);
            this.$refs.modal.loadBtn(false);
            if (res.res == 0) {
              window.localStorage.setItem("slist", JSON.stringify(res.data));
              this.$modal.hide("loadXls");
              this.$notify({
                type: "success",
                message: "导入成功",
              });
              this.$emit("success");
            }
          });
      }
    },
    onError(err) {
      // this.$refs.modal.loadBtn(false)
      console.log(err);
    },
    onConfirmXls() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$refs.upload.submit();
        }
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xls";
      const extension2 = testmsg === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        this.$refs.modal.loadBtn(false);
      }

      return extension || extension2;
    },
    downloadTemp() {
      window.location.href = this.$url + "/files/import/成员数据.xlsx";
    },
  },
};
